import React, { useEffect } from "react";
import { useCookies } from "@magalu/rave/router";
import { PlatformHooksProvider } from "@magalu/ravejs-platform-hooks";
import { EventsProvider } from "@magalu/ravejs-event-layer";
import { ErrorBoundary } from "@magalu/rave/errorBoundary";
import { whereami } from "@magalu/rave/utils";
import useClientEnv from '@magalu/rave/config/useClientEnv';
import { WishlistContext } from "@magalu/ecomm-components/contexts/WishlistContext";
import cookieParser from "@magalu/ecomm-components/rave/utils/cookieParser";
import userTrack from "@magalu/ecomm-components/rave/utils/userTrack";
import { recs } from "./constants/recs";
import events from "./events";
export const render = ({ Component, pageProps }) => {
    const { client } = useClientEnv();
    const cookie = useCookies(undefined, cookieParser);
    useEffect(() => {
        if (whereami.isClientSide) {
            userTrack.generateTrackId(client === null || client === void 0 ? void 0 : client.cookieDomain);
        }
    }, []);
    const appOnError = (_, errorInfo) => {
        // TODO: add track for errors
        console.error(errorInfo);
    };
    const accountData = cookie.getCookie("ml2_sid_c");
    const pageCookies = cookie.getCookies();
    return (<EventsProvider events={events()} globalData={Object.assign(Object.assign({}, pageProps), { cookies: pageCookies, device: "mobile", recs })}>
      <PlatformHooksProvider config={pageProps === null || pageProps === void 0 ? void 0 : pageProps.manifest}>
        <ErrorBoundary onError={appOnError} fallback={<p>Something went wrong!</p>}>
          <WishlistContext values={{
            isLogged: Boolean(accountData),
            userId: cookie.getCookie("stwu"),
            useUnloggedWishlist: true,
        }}>
            <Component {...pageProps}/>
          </WishlistContext>
        </ErrorBoundary>
      </PlatformHooksProvider>
    </EventsProvider>);
};
