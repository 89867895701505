export default [
  {
    "name": "home",
    "path": "/"
  },
  {
    "name": "todas-marcas",
    "path": "/marcas/"
  },
  {
    "name": "produto",
    "path": "/:slug/p/:productId/:categoryId/:subCategoryId/"
  },
  {
    "name": "buybox",
    "path": "/:slug/m/:productId/:categoryId/:subCategoryId/"
  },
  {
    "name": "not-found",
    "path": "/404/"
  },
  {
    "name": "internal-not-found-page",
    "path": "/error-404/"
  },
  {
    "name": "error",
    "path": "/500/"
  },
  {
    "name": "internal-error-page",
    "path": "/error-500"
  }
]
