export default {
  "logLevel": "error",
  "environment": "production",
  "cookieDomain": ".magazineluiza.com.br",
  "siteDomain": "https://m.magazineluiza.com.br",
  "otelEnabled": true,
  "ads": {
    "host": "https://securepubads.g.doubleclick.net/gampad/adx",
    "timeout": 5000
  },
  "datasource": {
    "graphql": {
      "host": "https://federation.magazineluiza.com.br",
      "timeout": 5000
    }
  },
  "cart": {
    "host": "https://sacolamobile.magazineluiza.com.br"
  },
  "magaluads": {
    "host": "https://ads-query.luizalabs.com"
  },
  "shipping": {
    "timeout": 5000
  }
}
