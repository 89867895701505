export const merge = (existing: Record<string, unknown>, incoming: Record<string, unknown>): Record<string, unknown> => ({ ...existing, ...incoming });

export const cachePolicies = {
  Brand: { keyFields: ['slug'] },
  Category: {
    fields: {
      meta: {
        merge,
      },
      subcategories: {
        merge,
      },
    },
    keyFields: object =>
      object?.subcategories ? `${object.id}:Sub` : `${object.id}:NoSub`,
  },
  FilterValueResponse: {
    keyFields: object => (object.type ? ['slug', 'id', 'type'] : ['slug', 'id']),
  },
  Product: {
    fields: {
      seller: {
        details: {
          address: {
            merge,
          },
        },
      },
    },
    keyFields: ['variationId', 'id'],
  },
  Query: {
    fields: {
      recommendation: {
        merge,
      },
    },
  },
  Recommendation: {
    keyFields: ['id', 'title'],
  },
  Attribute: {
    keyFields: object => object.type,
  },
  Variation: {
    keyFields: object => `${object.id}:${object.type}:${object.value}`,
  },
};
