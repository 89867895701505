import { addToCart } from "@magalu/ecomm-components/rave/events/GA4/addToCart";
import allInHandleWishlist from "@magalu/ecomm-components/rave/events/AllIn/handleWishlist";
import allInPageView from "@magalu/ecomm-components/rave/events/AllIn/pageView";
import { addToWishList } from "@magalu/ecomm-components/rave/events/GA4/addToWishList";
import { brandListItemClick } from "@magalu/ecomm-components/rave/events/GA/all-brands";
import { productAttributeClick } from "@magalu/ecomm-components/rave/events/GA/attribute";
import { pageView } from "@magalu/ecomm-components/rave/events/GA/pageView";
import { clickPagination } from "@magalu/ecomm-components/rave/events/GA/pagination";
import {
  seeMoreOffers,
  buyBoxAddToCart,
} from "@magalu/ecomm-components/rave/events/GA/buy-box";
import { productLabelClick } from "@magalu/ecomm-components/rave/events/GA/product-label";
import {
  searchClear,
  searchClose,
  searchSubmit,
} from "@magalu/ecomm-components/rave/events/GA/search";
import {
  shippingClick,
  shippingError,
  shippingImpression,
} from "@magalu/ecomm-components/rave/events/GA/shipping";
import {
  zipCodeClick,
  zipCodeResponseError,
  zipCodeResponseSuccess,
} from "@magalu/ecomm-components/rave/events/GA/zipCode";
import {
  addToCartSuccess,
  addToCartError,
} from "@magalu/ecomm-components/rave/events/GA/add-to-cart";
import { filterLinkListClick } from "@magalu/ecomm-components/rave/events/GA/filter";
import {
  sellerInfoPdpLinkClick,
  sellerScoreImpression,
  sellerScoreCalcInfoClick,
} from "@magalu/ecomm-components/rave/events/GA/seller";
import { reviewPdpClick } from "@magalu/ecomm-components/rave/events/GA/review";
import {
  questionListImpression,
  questionFormSeeMore,
  questionClickConfirmQuestion,
  questionClickOpenConfirm,
  questionFeedbackResponseError,
} from "@magalu/ecomm-components/rave/events/GA/questions";
import {
  productBenefitCardClick,
  productBenefitsRefundLinkClick,
  productBenefitsView,
} from "@magalu/ecomm-components/rave/events/GA/productBenefit";

import { gateKeeperButtonClick } from "./events/gate-keeper";
import {
  luContentClick,
  luContentView,
} from "@magalu/ecomm-components/rave/events/GA/luContent";
import { productViewItem } from "@magalu/ecomm-components/rave/events/GA/product-view-item";
import { showcaseItemClick } from "@magalu/ecomm-components/rave/events/GA/showcase-item-click";
import { showcaseItemView } from "@magalu/ecomm-components/rave/events/GA/showcase-item-view";
import {
  productPaymentChange,
  productPaymentClick,
} from "@magalu/ecomm-components/rave/events/GA/product-payment";

import tikTokViewItem from "@magalu/ecomm-components/rave/events/TikTok/view-item";
import tiktokAddToCart from "@magalu/ecomm-components/rave/events/TikTok/add-to-cart";
import criteoPageView from "@magalu/ecomm-components/rave/events/Criteo/pageView";
import spotifyAddToCart from "@magalu/ecomm-components/rave/events/SpotifyAdAnalytics/add-to-cart";
import spotifyAddToWishList from "@magalu/ecomm-components/rave/events/SpotifyAdAnalytics/add-to-wish-list";
import spotifyPageView from "@magalu/ecomm-components/rave/events/SpotifyAdAnalytics/page-view";
import rtbhAddToCart from "@magalu/ecomm-components/rave/events/RTBHouse/add-to-cart";
import rtbhAddToWishList from "@magalu/ecomm-components/rave/events/RTBHouse/add-to-wish-list";
import rtbhPageView from "@magalu/ecomm-components/rave/events/RTBHouse/page-view";
import stewiePageView from "@magalu/ecomm-components/rave/events/Stewie/page-view";
import stewieAddToCart from "@magalu/ecomm-components/rave/events/Stewie/add-to-cart";
import stewieProductListClick from "@magalu/ecomm-components/rave/events/Stewie/product-list-click";

import storeViewedProductMagaluAds from "@magalu/ecomm-components/rave/events/MagaluAds/storeViewedProduct";
import showcaseProductListClick from "@magalu/ecomm-components/rave/events/MagaluAds/showcaseProductListClick";
import showcaseProductListView from "@magalu/ecomm-components/rave/events/MagaluAds/showcaseProductListView";
import { cloudRetailView } from "@magalu/ecomm-components/rave/events/CloudRetail/viewItem";
import { cloudRetailAddToCart } from "@magalu/ecomm-components/rave/events/CloudRetail/addToCart";

import { viewItem } from "@magalu/ecomm-components/rave/events/GA4/viewItem";
import { viewItemList } from "@magalu/ecomm-components/rave/events/GA4/viewItemList";
import { selectItem } from "@magalu/ecomm-components/rave/events/GA4/selectItem";
import { shareClick } from "@magalu/ecomm-components/rave/events/GA4/share";
import { shippingResponseErrorGA4 } from '@magalu/ecomm-components/rave/events/GA4/shippingResponseErrorGA4';

import addToCartSuccessCookie from "@magalu/ecomm-components/rave/events/Cookies/addToCart";

const events = (): any => ({
  pageView: [
    pageView,
    allInPageView,
    criteoPageView,
    spotifyPageView,
    rtbhPageView,
    stewiePageView,
    storeViewedProductMagaluAds,
    cloudRetailView,
  ],
  "addtocart:success": [
    addToCart,
    addToCartSuccess,
    addToCartSuccessCookie,
    tiktokAddToCart,
    spotifyAddToCart,
    rtbhAddToCart,
    stewieAddToCart,
    cloudRetailAddToCart,
  ],
  "addtocart:error": [addToCartError],
  "click:zipCodeBar": [zipCodeClick],
  "click:brandListItem": [brandListItemClick],
  "click:pagination": [clickPagination],
  "buybox:addtocart": [buyBoxAddToCart],
  "buybox:seemoreoffers": [seeMoreOffers],
  "product:label:click": [productLabelClick],
  "listlink:click": [filterLinkListClick],
  "lucontent:vertical:view": [luContentView],
  "lucontent:vertical:item:click": [luContentClick],
  "gatekeeper:click": [gateKeeperButtonClick],
  "search:submit": [searchSubmit],
  "search:clear": [searchClear],
  "search:close": [searchClose],
  "product:share:click": [shareClick],
  "product:shipping:change": [shippingImpression],
  "product:shipping:click": [shippingClick],
  "product:shipping:error": [shippingError, shippingResponseErrorGA4],
  "product:shipping:details": [productViewItem],
  "product:wishlist:click": [
    allInHandleWishlist,
    spotifyAddToWishList,
    rtbhAddToWishList,
    addToWishList,
  ],
  "product:attribute:selected": [productAttributeClick],
  "product:benefits:tags:click": [productBenefitCardClick],
  "product:benefits:refund:link:click": [productBenefitsRefundLinkClick],
  "product:benefits:view": [productBenefitsView],
  "product:installments:click": [productPaymentClick],
  "product:paymentmethod:change": [productPaymentChange],
  "product:viewItem": [viewItem, tikTokViewItem],
  "seller:info:impression": [sellerScoreImpression],
  "seller:info:pdp:open:modal:click": [sellerInfoPdpLinkClick],
  "seller:score:calc:info:click": [sellerScoreCalcInfoClick],
  "reviewpdp:button:click": [reviewPdpClick],
  "question-list:impression": [questionListImpression],
  "question-form:see-more": [questionFormSeeMore],
  "question-feedback:error": [questionFeedbackResponseError],
  "question-confirm:click": [questionClickConfirmQuestion],
  "question:form:submit": [questionClickOpenConfirm],
  "zipcodebar:response:error": [zipCodeResponseError],
  "zipcodebar:response:success": [zipCodeResponseSuccess],
  "productlist:click": [selectItem, showcaseProductListClick],
  "product:item:click": [selectItem, showcaseItemClick, stewieProductListClick],
  "product:view": [viewItemList, showcaseProductListView, showcaseItemView],
});

export default events;
