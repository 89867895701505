
import { render as renderCustom } from '../entry';

import { client, ClientProvider } from "@magalu/rave/graphql";
import { ConfigProvider } from "@magalu/rave/config/Provider";

// Data Source Configs
import { configs } from '../datasource/config';
const { cachePolicies = {}, localTypeDefs,  middlewares = [] } = configs ;

import clientConfig from '../containers/client-config';

import routes from '../routes';
import getClientSideInstrumentation from "@magalu/rave/otel/get-client-side-instrumentation";

getClientSideInstrumentation({
  appName: "mixer-site-mobile",
  appVersion: "1.58.0",
  routes,
  config: {"cwv":true,"documentLoad":true,"error":true,"fetch":true},
});

const CustomRender = ({ Component, pageProps }) => {
  return renderCustom({ Component, pageProps });
};

export const withRaveContexts = (children) => {
  const configEnvs = { client: { ...clientConfig } };
  let clientProviderParams = {};

  clientProviderParams = {
    config: {
      ...({
  "configsDir": "config",
  "serverDefaultOptions": {
    "query": {
      "errorPolicy": "all",
      "fetchPolicy": "network-only"
    }
  }
} || {}),
      ...(configEnvs?.client?.datasource?.graphql || {})
    },
  };
  clientProviderParams['cachePolicies'] = cachePolicies;
  clientProviderParams['localTypeDefs'] = localTypeDefs;
  clientProviderParams['middlewares'] = middlewares;

  return (
    <ClientProvider {...clientProviderParams}>
      <ConfigProvider config={configEnvs}>{children}</ConfigProvider>
    </ClientProvider>
  );
};

export default function CustomApp({ Component, pageProps = {} }) {
  let parsedPageProps = {...pageProps};

    return withRaveContexts(<CustomRender Component={Component} pageProps={parsedPageProps} />);
};
